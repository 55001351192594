import Plugin from '@swup/plugin';
import { toDash } from '../string'
import { $html } from '../dom';

export default class SwupModularPlugin extends Plugin {
	name = 'SwupModularPlugin';

	/* Version requirements */
	requires = { swup: '>=4' };

	/* Default options */
	defaults = {
        modularInstance: false
    };

	/**
	 * Executed each time an instance of this plugin is created.
	 * Can be used for things that don't rely on access to the swup instance.
	 */
	constructor(options = {}) {
		super();

		/* Merge default options and user options */
		this.options = { ...this.defaults, ...options };
	}

	/**
	 * Executed when swup is initialized with this plugin.
	 * You can use this.swup here to access the swup instance.
	 */
	mount() {
		this.swup.hooks.before('content:replace', (visit) => {
            for(let container of visit.containers) {
                const oldContainer = this.options.modularInstance?.el.querySelector(container);
                this.options.modularInstance?.call('destroy', oldContainer, 'app');
            }
        });

        this.swup.hooks.on('content:replace', (visit) => {
            this.updateDocumentAttributes(visit);

            for(let container of visit.containers) {
                const newContainer = this.options.modularInstance?.el.querySelector(container)
                this.options.modularInstance?.call('update', newContainer, 'app');
            }
        })
	}

    updateDocumentAttributes(visit) {
        if (visit.fragmentVisit) return

        // Retrieve HTML dataset on next container and update our real html element dataset accordingly
        const parser        = new DOMParser()
        const nextDOM       = parser.parseFromString(visit.to.html, 'text/html')
        const newDataset    = Object.assign({}, nextDOM.querySelector('html').dataset)

		$html.setAttribute('dir', nextDOM.documentElement.getAttribute('dir'))
		$html.setAttribute('lang', nextDOM.documentElement.getAttribute('lang'))

        Object.entries(newDataset).forEach(([key, val]) => {
            $html.setAttribute(`data-${toDash(key)}`, val)
        })
    }

	/**
	 * Executed when a swup instance with this plugin is disabled.
	 * You can use this.swup here to access the swup instance.
	 * Make sure to undo any DOM changes and remove event listeners here.
	 */
	unmount() {
		/* this.swup.hooks.off('link:click', this.handleLinkClick) */
	}
}