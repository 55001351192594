import Modal from "./Modal";

export default class ModalVideo extends Modal {

    static CLOSE_TIMEOUT = 500

    static MOBILE_BREAKPOINT = 500

    static VIDEO_PLATFORMS = {
        direct: {
            pattern: /\.mp4(\?|$)/,
            format: (url) => `<video controls autoplay><source src="${url}" type="video/mp4"></video>`
        },
        youtube: {
            pattern: /(?:youtube\.com\/.*v=|youtu\.be\/)([0-9A-Za-z_-]{11})/,
            format: (url) => {
                const videoId = url.match(ModalVideo.VIDEO_PLATFORMS.youtube.pattern)[1];
                return `<iframe src="https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1" tabindex="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            }
        },
        vimeo: {
            pattern: /vimeo\.com\/(\d+)/,
            format: (url) => {
                const videoId = url.match(ModalVideo.VIDEO_PLATFORMS.vimeo.pattern)[1];
                return `<iframe src="https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0" tabindex="0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
            }
        }
    }

    constructor(m) {
        super(m);

        this.$inner = this.$('inner')[0];

        this.videoDesktop = {
            url: this.getData('url'),
            platform: this.detectVideoPlatform(this.getData('url'))
        }

        this.videoMobile = {
            url: this.getData('url-mobile') || this.getData('url'),
            platform: this.detectVideoPlatform(this.getData('url-mobile'))
        }

        this.closeTimeout = null;
    }

    detectVideoPlatform(url) {
        for (const [platform, { pattern }] of Object.entries(ModalVideo.VIDEO_PLATFORMS)) {
            if (pattern.test(url)) {
                return platform;
            }
        }

        return "embed";
    }

    onOpen() {
        let video = {}

        if (window.innerWidth < ModalVideo.MOBILE_BREAKPOINT) {
            if (!this.videoMobile.url) {
                // If no mobile video is provided, fallback to desktop video
                video = this.videoDesktop
            } else {
                video = this.videoMobile
            }
        } else {
            video = this.videoDesktop
        }

        const platform = ModalVideo.VIDEO_PLATFORMS[video.platform];
        this.$inner.innerHTML = platform && platform.format(video.url);
    }

    onDeactivate() {
        this.closeTimeout && clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(() => {
            this.$inner.innerHTML = "";
        }, ModalVideo.CLOSE_TIMEOUT);
    }
}
