import { module } from 'modujs';
import { whenReady } from "../utils/fonts.js";
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { FONT } from '../config.js';

gsap.registerPlugin(SplitText)
export default class SplitLines extends module {
    constructor(m) {
        super(m);

        // Binding
        this.onResizeBind = this.onResize.bind(this);

        // Options
        this.delay = parseFloat(this.getData('delay')) || 0.0;
        this.stagger = parseFloat(this.getData('stagger')) || 0.02;
    }

    init() {
        this.bindEvents()
        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    destroy() {
        super.destroy()

        this.clear()
        this.unbindEvents()
    }

    bindEvents() {
        window.addEventListener('resize', this.onResizeBind);
    }

    unbindEvents() {
        window.removeEventListener('resize', this.onResizeBind);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize() {
       this.reSplit()
    }

    onFontsLoaded(fonts) {
        this.reSplit()
    }

    ///////////////
    // Methods
    ///////////////
    onInView(args) {
        if (args.way == 'leave') {
            this.clear()
        }
    }

    split() {
        this.splitObject = new SplitText(this.el, { type: "lines", linesClass: "u-lines-split" });
        this.splitObject.lines.forEach((line, i) => line.style.setProperty('--line-index', i));

        if (this.delay) {
            this.el.setProperty('--master-delay', this.delay);
        }
    }

    reSplit() {
        this.splitObject?.revert?.();

        requestAnimationFrame(() => {
            this.split();
        })
    }

    clear() {
        this.splitObject?.lines?.forEach((line) => line.style.removeProperty('--line-index'));
        this.splitObject?.revert?.();
        this.splitObject = null;
    }
}
