import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: 'onChange',
            submit: 'onSubmit',
            click: {
                reset: 'onReset'
            }
        }

        this.baseUrl = this.getData('base-url');
        this.submitOnChange = this.getData('submit-on-change') === 'true';
        this.el.classList.toggle('is-submit-on-change', this.submitOnChange)
    }

    onChange(e) {
        if(!this.submitOnChange) return;

        this.update()
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.update(true);
    }

    onReset(e) {
        e.preventDefault();
        e.stopPropagation();

        // Reset form to default state
        this.el.reset();
        // Force remove the `checked` attribute on inputs because native reset goes to default values & a `checked` attribute is considered default value
        this.el.querySelectorAll('input[checked]').forEach(el => el.removeAttribute('checked'));
        this.el.querySelectorAll('input[value="all"]').forEach(el => el.setAttribute('checked', ''));
        // Force remove the `selected` attribute on options because native reset goes to default values & a `selected` attribute is considered default value
        this.el.querySelectorAll('option[selected]').forEach(el => el.removeAttribute('selected'));

        if(this.submitOnChange) this.update();
    }

    update(force = false) {
        this.formData = new FormData(this.el);
        this.updateRules();
        this.updateParams();
        this.applyFilters(force);
    }

    updateRules() {
        this.rules = {};
        for (const [ key, value ] of this.formData.entries()) {
            if (key && value.length) { // Do not consider empty value
                if (!this.rules[key]) {
                    this.rules[key] = [];
                }

                this.rules[key].push(value);
            }
        }
    }

    updateParams() {
        this.urlParams = new URLSearchParams();

        for(let [rule, value] of Object.entries(this.rules)) {
            if(rule == 'orderby' && value == 'default') continue;
            this.urlParams.set(rule, value.join(','))
        }
    }

    applyFilters(force = false) {
        if(force || window.location.search != '?'+this.urlParams.toString()) {
            this.call('goTo', this.baseUrl+'?'+this.urlParams.toString(), 'Load');
        }
    }
}
