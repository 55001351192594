import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: 'onChange',
            click: {
                reset: 'onReset'
            }
        }

        this.baseUrl = this.getData('base-url');
    }

    onChange(e) {
        this.update()
    }

    onReset(e) {
        e.preventDefault();
        e.stopPropagation();

        // Reset form to default state
        this.el.reset();
        // Force remove the `checked` attribute on inputs because native reset goes to default values & a `checked` attribute is considered default value
        this.el.querySelectorAll('input[checked]').forEach(el => el.removeAttribute('checked'));
        this.el.querySelectorAll('input[value="all"]').forEach(el => el.setAttribute('checked', ''));
        // Force remove the `selected` attribute on options because native reset goes to default values & a `selected` attribute is considered default value
        this.el.querySelectorAll('option[selected]').forEach(el => el.removeAttribute('selected'));

        this.update();
    }

    update() {
        this.formData = new FormData(this.el);
        this.updateRules();
        this.updateParams();
        this.applyFilters();
    }

    updateRules() {
        this.rules = {};
        for (const [ key, value ] of this.formData.entries()) {
            if (key && value.length) { // Do not consider empty value
                if (!this.rules[key]) {
                    this.rules[key] = [];
                }

                this.rules[key].push(value);
            }
        }
    }

    updateParams() {
        this.urlParams = new URLSearchParams();

        for(let [rule, value] of Object.entries(this.rules)) {
            if(rule == 'orderby' && value == 'default') continue;
            this.urlParams.set(rule, value.join(','))
        }
    }

    applyFilters() {
        if(window.location.search != '?'+this.urlParams.toString()) {
            this.call('goTo', this.baseUrl+'?'+this.urlParams.toString(), 'Load');
        }
    }
}
