import { module } from 'modujs';
import Swup from 'swup';
import SwupFragmentPlugin from '@swup/fragment-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupModularPlugin from '../utils/swup/SwupModularPlugin';
import SwupLSPlugin from '../utils/swup/SwupLSPlugin';
import SwupJsPlugin from '@swup/js-plugin';
import gsap from 'gsap'
import { CSS_CLASS, CUSTOM_EVENT } from '../config';
import { $body, $html } from '../utils/dom';

export default class extends module {
    constructor(m) {
        super(m);

        // Binding
        this.onVisitStart       = this.onVisitStart.bind(this);
        this.onContentReplace   = this.onContentReplace.bind(this);
        this.onAnimationInStart = this.onAnimationInStart.bind(this);
        this.onPageView         = this.onPageView.bind(this);

        this.rulesPaths = JSON.parse(this.getData('rules-paths')) || {};

        // UI
        this.$spinner = document.querySelector('.c-spinner')
        this.$transition = document.querySelector('.c-transition')
        this.$transitionShutters = this.$transition.children
    }

    init() {
        this.load = new Swup({
            containers: ['[data-load-container]'],
            animateHistoryBrowsing: true,
            linkToSelf: 'navigate',
            ignoreVisit: (url, { el, event } = {}) => {
                if(url.lastIndexOf('#show-cookie-consent') != -1) {
                    return true;
                }
                return el?.closest('[data-no-swup]')
            },
            // cache: false,
            plugins: this.#getSwupPlugins(),
        });

        this.load.hooks.on('visit:start', this.onVisitStart)
        this.load.hooks.on('content:replace', this.onContentReplace);
        this.load.hooks.on('animation:in:start', this.onAnimationInStart);
        this.load.hooks.on('page:view', this.onPageView);
        this.load.hooks.before('content:announce', this.beforeContentAnnounce);
    }

    #getSwupPlugins() {
        const rules = [
            {
                from: `${this.rulesPaths['resources-listing']}(.*)`,
                to: `${this.rulesPaths['resources-listing']}(.*)`,
                containers: ['#listing'],
                name: 'listing-update',
            },
            {
                from: `${this.rulesPaths['resources-listing']}(.*)`,
                to: `${this.rulesPaths['resources-single']}/:slug`,
                containers: ['#modal'],
                name: 'open-modal'
            },
            {
                from: `${this.rulesPaths['resources-single']}/:slug`,
                to: `${this.rulesPaths['resources-listing']}(.*)`,
                containers: ['#modal', '#listing'],
                name: 'close-modal'
            }
        ];

        const plugins = [
            new SwupFragmentPlugin({
                rules: rules,
            }),
            new SwupLSPlugin({ modularInstance: this }),
            new SwupModularPlugin({ modularInstance: this }),
            new SwupPreloadPlugin({ preloadInitialPage: false }),
            new SwupA11yPlugin({
                announcements: {
                    'fr-FR': {
                        visit: 'Navigué vers : {title}',
                        url: 'Nouvelle page à {url}'
                    },
                    'fr-CA': {
                        visit: 'Navigué vers : {title}',
                        url: 'Nouvelle page à {url}'
                    },
                    '*': {
                        visit: 'Navigated to: {title}',
                        url: 'New page at {url}'
                    }
                }
            }),
            new SwupJsPlugin({
                animations: [
                    this.defaultTransition(),
                ]
            })
        ]

        return plugins;
    }

    // ==========================================================================
    // HOOKS
    // ==========================================================================

    onVisitStart(visit) {
        // Dispatch custom event
        window.dispatchEvent(new CustomEvent(CUSTOM_EVENT.VISIT_START, { detail: visit }))

        if(!visit.fragmentVisit) {
            $html.classList.remove(CSS_CLASS.READY)
            $html.classList.remove(CSS_CLASS.LOADED)
            $html.classList.add(CSS_CLASS.LOADING)
        } else {
            visit.a11y.focus = false;

            switch (visit.fragmentVisit.name) {
                case 'open-modal':
                    this.call('show', null, 'Dialog')
                    break
                case 'close-modal':
                    this.call('close', null, 'Dialog')
                    break;
                case 'listing-update':
                    this.call('scrollTo', { target: '#listing', options: { offset: -200 } }, 'Scroll')
                    break;
            }
        }
    }

    onContentReplace(visit) {
        const adminBar = document.getElementById('wpadminbar')
        if(adminBar) {
            adminBar.remove();
            var parser = new DOMParser();
            var doc = parser.parseFromString(visit.to.html, "text/html");
            var newAdminBar = doc.getElementById('wpadminbar');
            $body.appendChild(newAdminBar)
        }

        if(!visit.fragmentVisit) {
            $html.classList.remove(CSS_CLASS.LOADING)
            $html.classList.add(CSS_CLASS.LOADED)

            this.call('rebindTogglers', null, 'Modal');
        } else {
            if(visit.fragmentVisit.name == 'listing-update') {
                this.call('scrollTo', { target: '#listing', options: { offset: -200 } }, 'Scroll')
            }
        }
    }

    onAnimationInStart(visit) {
        if(!visit.fragmentVisit) {
            setTimeout(() => {
                $html.classList.add(CSS_CLASS.READY)
            }, 50)
        }
    }

    onPageView(visit) {
        if(window.location.search.length) {
            const searchParams = new URLSearchParams(window.location.search)
            const search_term = searchParams.get('s')

            if(search_term != null) {
                const other_queries = {}
                for (let [key, value] of searchParams.entries()) {
                    if(key != 's') {
                        key = "q_"+key;
                        if(other_queries[key]) other_queries[key]+=','+value
                        else other_queries[key] = value
                    }
                }

                dataLayer.push({
                    event: "view_search_results",
                    eventModel: {
                        search_term,
                        ...other_queries
                    }
                })
            }
        }
    }

    beforeContentAnnounce = (visit) => {
        if(visit.fragmentVisit && visit.fragmentVisit.name == 'listing-update') {
            switch($html.getAttribute('lang')) {
                case 'fr-CA':
                case 'fr-FR':
                    visit.a11y.announce = `${document.getElementById('listing-items').children.length} résultats trouvés.`;
                    break;
                default:
                    visit.a11y.announce = `${document.getElementById('listing-items').children.length} results found.`;
                    break;
            }
        }
    }

    // ==========================================================================
    // METHODS
    // ==========================================================================

    goTo(url) {
        this.load.navigate(url)
    }

    // ==========================================================================
    // TRANSITIONS
    // ==========================================================================

    defaultTransition() {
        return {
            from: '(.*)',
            to: '(.*)',
            out: (done, data) => {

                // Set the color theme to prevent flash when next time is set
                const currentTheme = document.documentElement.getAttribute('data-theme')
                this.$transition.dataset.theme = currentTheme
                this.$spinner.dataset.theme = currentTheme

                const duration = .7

                const tl = gsap.timeline({
                    paused: true,
                    onComplete: done
                })

                tl.fromTo(
                    this.$transitionShutters[0],
                    {
                        scale: 0.3,
                        skewX: '15deg',
                        y: '100%',
                        x: '10%',
                        rotate: '-15deg',
                    },
                    {
                        scale: 1,
                        skewX: '0deg',
                        y: '0%',
                        x: '0%',
                        rotate: '0deg',
                        ease: 'power3.inOut',
                        duration: duration
                    }
                );

                tl.fromTo(
                    this.$transitionShutters[1],
                    {
                        scale: 0.3,
                        skewX: '-15deg',
                        y: '100%',
                        x: '-15%',
                        rotate: '18deg',
                    },
                    {
                        scale: 1,
                        skewX: '0deg',
                        y: '0%',
                        x: '0%',
                        rotate: '0deg',
                        ease: 'power4.out',
                        duration: duration
                    },
                    `<+=${duration * 0.7}`
                );

                tl.play()
            },
            in: (done, data) => {
                // done();

                const duration = .5

                const tl = gsap.timeline({
                    paused: true,
                    onComplete: done
                })

                tl.fromTo(
                    this.$transitionShutters[1],
                    {
                        scale: 1,
                        skewX: '0deg',
                        y: '0%',
                        x: '0%',
                        rotate: '0deg',
                    },
                    {
                        scale: 0.3,
                        skewX: '3deg',
                        y: '100%',
                        x: '10%',
                        rotate: '-5deg',
                        ease: 'power4.in',
                        duration: duration
                    },
                );

                tl.fromTo(
                    this.$transitionShutters[0],
                    {
                        scale: 1,
                        skewX: '0deg',
                        y: '0%',
                        x: '0%',
                        rotate: '0deg',
                    },
                    {
                        scale: 0.3,
                        skewX: '-5deg',
                        y: '100%',
                        x: '-15%',
                        rotate: '8deg',
                        ease: 'power4.in',
                        duration: duration
                    },
                    `<+=0.1`
                );

                tl.play()
            }
        };
    }
}
