import { module } from 'modujs';
import { $html } from '../utils/dom';

export default class extends module {
    constructor(m) {
        super(m);

        this.$closeBtn = this.$('close')[0]
    }

    init() {
        // Prevent ESC to close dialog
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    onKeyDown(e) {
        if(e.key === 'Escape') {
            e.preventDefault()
            this.$closeBtn.click();
        }
    }

    show() {
        this.el.showModal();
        window.addEventListener('keydown', this.onKeyDown);

        // Prevent main scroll
        $html.style.overflow = 'hidden';

        requestAnimationFrame(() => {
            this.el.scrollTop = 0;
        })
    }

    update() {
        this.el.scrollTop = 0;
        document.getElementById('modal')?.focus?.()
    }

    close() {
        // Allow main scroll
        $html.style.overflow = '';

        window.removeEventListener('keydown', this.onKeyDown);
        this.el.close();
    }
}
