/**
 * Determines if the argument is object-like.
 *
 * A value is object-like if it's not `null` and has a `typeof` result of "object".
 *
 * @param  {*} x - The value to be checked.
 * @return {boolean}
 */

const isObject = x => (x && typeof x === 'object')

/**
 * Determines if the argument is a function.
 *
 * @param  {*} x - The value to be checked.
 * @return {boolean}
 */

const isFunction = x => typeof x === 'function'

function isSafari() {
    // Check if the userAgent contains "Safari" and "Version"
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Version') != -1) {
        return true;
    }
    return false;
}

/**
 * Determines if the device is a touch device.
 * @return {boolean}
 */
const isTouchDevice = () => {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};


export {
    isObject,
    isFunction,
    isSafari,
    isTouchDevice
}
