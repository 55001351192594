import { module as Module } from "modujs";
import { CUSTOM_EVENT } from '../config';
import { isTouchDevice } from "../utils/is";

export default class extends Module {
    constructor(m) {
        super(m);

        this.onResizeBind = this.onResize.bind(this);
    }

    ///////////////
    // Lifecyle
    ///////////////
    init() {
        if (isTouchDevice() || !this.el) return;

        this.wHeight = window.innerHeight;
        this.parallaxSpeed = parseFloat(this.el.dataset.scrollSpeed);

        this.bindEvents();
        this.computeScale();
    }

    destroy() {
        this.unbindEvents();
    }

    ///////////////
    // Events
    ///////////////
    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResizeBind);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResizeBind);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize() {
        this.wHeight = window.innerHeight;
        this.computeScale();
    }

    computeScale() {
        const elHeight = this.el.offsetHeight;
        const distance = Math.abs(this.wHeight * this.parallaxSpeed / 3);
        const scale = 1 + distance / elHeight;
        this.el.style.setProperty("--parallax-scale", scale);
    }
}
