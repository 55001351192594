import { module } from 'modujs';
import gsap from 'gsap'
import { CustomEase, DrawSVGPlugin } from 'gsap/all';

gsap.registerPlugin(CustomEase, DrawSVGPlugin)

export default class DrawSvg extends module {
    constructor(m) {
        super(m);

        // UI
        this.$paths = this.el.querySelectorAll('path');

        // Animation
        CustomEase.create('customEase', 'M0,0 C0.491,0.092 0,1 1,1');
    }

    init() {
        if (window.isReducedMotion) {
            return;
        }

        if (this.isHidden(this.el)) {
            return;
        }

        this.timeline = gsap.timeline({
            paused: true,
        });

        try {
            this.$paths.forEach(($path, index) => {
                const length = $path.getTotalLength();
                const duration = Math.min(length / 120, 1.5);

                this.timeline.fromTo(
                    $path,
                    {
                        drawSVG: 0,
                    },
                    {
                        duration: duration,
                        drawSVG:  '100%',
                        ease:     'customEase',
                    },
                    (index == 0 ? '' : '<+0.02')
                );
            });
        } catch (err) {
            console.warn('[DrawSVG.init] Could not tween paths', this.el, err);
        }
    }

    isHidden(elem) {
        const styles = window.getComputedStyle(elem);
        return styles.display === 'none' || styles.visibility === 'hidden';
    }

    destroy() {
        super.destroy();
        this.timeline?.kill?.();
    }

    onInview(args) {
        this.timeline?.play?.();
    }
}
