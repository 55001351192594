import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                button: 'onClick'
            }
        }
    }

    onClick(event) {
        event.preventDefault();

        const mainContent = document.querySelector('main[tabindex]');

        if(mainContent) {
            this.call('scrollTo', {
                target: mainContent.offsetTop > 100 ? mainContent : 200,
                options: {
                    duration: 1,
                    offset: -100,
                },
            }, 'Scroll')

            mainContent.focus();
        }
    }
}
