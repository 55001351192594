import { module } from 'modujs';
import { whenReady } from '../utils/fonts.js';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { CUSTOM_EVENT, FONT } from '../config.js';

gsap.registerPlugin(SplitText)
export default class SplitLines extends module {

    static CLASS = 'u-anim-text';

    constructor(m) {
        super(m);

        // Data
        this.lines = this.getData('lines') != null;
        this.words = this.getData('words') != null;
        this.chars = this.getData('chars') != null;

        // No split as default
        this.splitType = "";

        if (this.lines) this.splitType = "lines";
        if (this.words) this.splitType = "words";
        if (this.chars) this.splitType = "words, chars";
    }

    init() {
        this.bindEvents()

        this.el.setAttribute('aria-label', this.el.innerText);
    }

    destroy() {
        super.destroy()

        this.clear()
        this.unbindEvents()
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize = () => {
        if (!this.lines) return

        this.split?.revert?.();

        requestAnimationFrame(() => {
            this.split();
        })
    }

    onFontsLoaded(fonts) {
        this.split()
    }

    onInview(args) {
        if (this.splitObject && args.way == 'leave') {
            this.clear()
        }
    }

    ///////////////
    // Methods
    ///////////////

    split() {

        let splitParams = {
            type: this.splitType,
        }

        if (this.lines) splitParams.linesClass = `${SplitLines.CLASS}_line`
        if (this.words) splitParams.wordsClass = `${SplitLines.CLASS}_word`
        if (this.chars) splitParams.charsClass = `${SplitLines.CLASS}_char`

        // Splt text
        this.splitObject = new SplitText(this.el, splitParams);

        this.splitObject.chars?.forEach(($char, charIndex) => {
            $char.style.setProperty(
                "--char-index",
                charIndex
            );
            $char.setAttribute('aria-hidden', 'true');
        });

        // Set index for each word
        // if we are not splitting by chars
        // Otherwise, we will set the index in the chars loop
        if (!this.chars) {
            this.splitObject.words?.forEach(($word, wordIndex) => {
                $word.style.setProperty(
                    "--word-index",
                    wordIndex
                );
                $word.setAttribute('aria-hidden', 'true');
            });
        }

        this.splitObject.lines?.forEach(($line, lineIndex) => {
            $line.style.setProperty(
                "--line-index",
                lineIndex
            );
            $line.setAttribute('aria-hidden', 'true');
        });
    }

    clear() {
        this.splitObject?.revert?.();
        this.splitObject = null;
    }
}
