import { module } from 'modujs'
import { lazyLoadImage } from '../utils/image'
import LocomotiveScroll from 'locomotive-scroll'
import { $html } from '../utils/dom'

export default class extends module {
    constructor(m) {
        super(m);

        this.onScrollBind = this.onScroll.bind(this)
    }

    init() {
        requestAnimationFrame(() => {
            this.scroll = new LocomotiveScroll({
                modularInstance: this,
                scrollCallback: this.onScrollBind,
                lenisOptions: {
                    duration: .3,
                    lerp: 0.25
                }
            })

            this.scroll.scrollCallback();
        })

        // // Force scroll to top
        // if (history.scrollRestoration) {
        //     history.scrollRestoration = 'manual'
        //     window.scrollTo(0, 0)
        // }
    }

    addScrollElements(container) {
        this.scroll?.addScrollElements(container)
    }

    removeScrollElements(container) {
        this.scroll?.removeScrollElements(container)
    }

    onScroll({ scroll, progress } = {}) {
        if(!scroll) scroll = $html.scrollTop;

        //get direction
        if (progress) {
            if (progress > this.lastProgress) {
                if (this.scrollDirection != 1) {
                    this.scrollDirection = 1
                }
            } else {
                if (this.scrollDirection != -1) {
                    this.scrollDirection = -1
                }
            }

            if (this.scrollDirection < 0) {
                $html.classList.add('is-scrolling-up')
            } else {
                $html.classList.remove('is-scrolling-up')
            }
        }

        if (scroll > 100) {
            $html.classList.add('has-scrolled')
        } else {
            $html.classList.remove('has-scrolled')
        }

        this.lastProgress = progress
    }

    /**
     * Lazy load the related image.
     *
     * @see ../utils/image.js
     *
     * It is recommended to wrap your `<img>` into an element with the
     * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
     * will be applied on both the image and the parent wrapper.
     *
     * ```html
     * <div class="c-lazy o-ratio u-4:3">
     *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
     * </div>
     * ```
     *
     * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
     */
    lazyLoad(args) {
        lazyLoadImage(args.target, null, () => {
            //callback
        })
    }

    stop() {
        this.scroll?.stop?.();
    }

    scrollTo(params) {
        let { target, options } = params

        options = Object.assign({
            // Defaults
            duration: 1,
        }, options)

        this.scroll?.scrollTo(target, options)
    }

    destroy() {
        this.scroll?.lenisInstance.options.content.addEventListener('wheel', this.onWheel);
        this.scroll.destroy();
    }
}
