import Modal from "./Modal"

export default class extends Modal {
    constructor(m) {
        super(m)

        // UI
        this.$icons = this.$('icon')
    }

    onOpen(e) {
        const target = e.currentTarget
        const answer = Number(this.getData('answer-is-valid', target))
        const isCorrect = answer === 1

        this.el.classList.toggle('is-correct', isCorrect)

        const buttonIndex = Number(target.dataset.index);
        this.el.dataset.clickIndex = buttonIndex;

        this.$icons.forEach(($icon, index) => {
            $icon.classList.toggle('u-none', index !== buttonIndex)
            $icon.setAttribute('aria-hidden', index !== buttonIndex)
        })
    }
}
