import { module } from 'modujs';

/**
 * Time delay constants for label (in seconds).
 * @constant {number} DELAY_LABEL - Delay for label update after copy action.
 * @constant {number} DELAY_CSS_ANIM - Delay for CSS animation reset to avoid bug if there is no hover-label.
 */
const DELAY_LABEL = 1;
const DELAY_CSS_ANIM = 0.5;

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                toggler: 'onClick'
            }
        };

        this.label = this.$("label")[0];
        this.live = this.$("live")[0];
        this.labelCopy = this.label ? this.label.textContent : null;
        this.labelCopied = this.getData('copied-label') || null;
        this.labelWidth = 0;

        this.copyURL = this.getData('url');

        this.labelTimeout = null;
        this.cssAnimTimeout = null;
    }

    clearTimeouts() {
        if (this.labelTimeout) {
            clearTimeout(this.labelTimeout);
            this.labelTimeout = null;
        }
        if (this.cssAnimTimeout) {
            clearTimeout(this.cssAnimTimeout);
            this.cssAnimTimeout = null;
        }
    }

    async copyToClipboard(text, element) {
        try {
            await navigator.clipboard.writeText(text);

            element.classList.add('is-copied');

            this.labelWidth = this.label.offsetWidth;
            this.label.style.width = this.labelWidth + 'px';
            this.label.textContent = this.labelCopied || "Copié";
            this.live.textContent = this.labelCopied || "Copié";

            this.clearTimeouts();
            this.labelTimeout = setTimeout(() => {
                this.label.style.width = '';
                this.label.textContent = this.labelCopy || "Copier";
                this.live.textContent = '';
            }, DELAY_LABEL * 1000);

            this.cssAnimTimeout = setTimeout(() => {
                element.classList.remove('is-copied');
            }, (DELAY_LABEL + DELAY_CSS_ANIM) * 1000);
        } catch (err) {
            console.error('Failed to copy text to clipboard', err);
        }
    }

    onClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const textToCopy = this.copyURL || window.location.href;
        if (textToCopy) {
            this.copyToClipboard(textToCopy, e.target);
        }
    }
}
