import { module } from 'modujs';
import { map, clamp } from '../utils/maths';
import { CUSTOM_EVENT } from '../config';

export default class extends module {
    constructor(m) {
        super(m);

        this.$items = Array.from(this.$('item'));

        this.currentIndex = 0;
        this.totalItems = this.$items.length;
        this.itemsStack = 4;
    }

    init() {
        this.bindEvents();
        this.compute();
    }

    ///////////////
    // Methods
    ///////////////
    compute() {
        if(window.innerWidth < 700 || window.innerWidth / window.innerHeight > 2 / 1 || window.matchMedia('(hover:none)').matches) {
            this.reset();
            return;
        }

        this.el.style.setProperty('--cards-pile-total', this.totalItems)

        for(let i = 1; i <= this.totalItems; i++) {
            this.$items[i-1].style.setProperty('--cards-pile-index', i)
            this.$items[i-1].style.setProperty('--cards-pile-item-height', 'auto');
            this.$items[i-1].style.setProperty('--cards-pile-item-height', this.$items[i-1].getBoundingClientRect().height+'px')
        }
    }

    reset() {
        this.el.style.setProperty('--cards-pile-total', '');
        for(let i = 1; i <= this.totalItems; i++) {
            for(let property of ['index','item-height']) {
                this.$items[i-1].style.setProperty('--cards-pile-'+property, '');
            }
        }
    }

    ///////////////
    // Events
    ///////////////
    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize = () => {
        this.compute();
    }

    onScrollProgress(progress) {
        const gap = 1 / (this.totalItems - 1);

        this.currentIndex = Math.floor((progress+gap/2)/gap);

        for(let i = 0; i < this.totalItems - 1; i++) {
            const start = i * gap;
            const end = start + gap * this.itemsStack;

            const itemProgress = clamp(0,1,map(progress, start, end, 0, 1));

            this.$items[i].style.setProperty('--progress', itemProgress)
            this.$items[i].style.visibility = itemProgress == 1 ? 'hidden' : '';
        }
        this.$items[this.totalItems - 1].style.setProperty('--progress', 0)
    }
}
