import Plugin from '@swup/plugin';
import { toDash } from '../string'
import { $html } from '../dom';

export default class SwupLSPlugin extends Plugin {
	name = 'SwupLSPlugin';

	/* Version requirements */
	requires = { swup: '>=4' };

	/* Default options */
	defaults = {
        modularInstance: false
    };

	/**
	 * Executed each time an instance of this plugin is created.
	 * Can be used for things that don't rely on access to the swup instance.
	 */
	constructor(options = {}) {
		super();

		/* Merge default options and user options */
		this.options = { ...this.defaults, ...options };
	}

	/**
	 * Executed when swup is initialized with this plugin.
	 * You can use this.swup here to access the swup instance.
	 */
	mount() {
        this.beforeContentReplace = this.beforeContentReplace.bind(this)
        this.onContentReplace = this.onContentReplace.bind(this)

		this.swup.hooks.before('content:replace', this.beforeContentReplace);
        this.swup.hooks.on('content:replace', this.onContentReplace)
	}

    beforeContentReplace(visit) {
        if(visit.fragmentVisit) {
            for(let container of visit.fragmentVisit.containers) {
                const oldContainer = this.options.modularInstance?.el.querySelector(container)
                this.options.modularInstance?.call('removeScrollElements', oldContainer, 'Scroll');
            }
        }
    }

    onContentReplace(visit) {
		if(visit.fragmentVisit) {
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					for(let container of visit.fragmentVisit.containers) {
						const newContainer = this.options.modularInstance?.el.querySelector(container)
						this.options.modularInstance?.call('addScrollElements', newContainer, 'Scroll');
					}
				})
			})
        }
    }

	/**
	 * Executed when a swup instance with this plugin is disabled.
	 * You can use this.swup here to access the swup instance.
	 * Make sure to undo any DOM changes and remove event listeners here.
	 */
	unmount() {
		/* this.swup.hooks.off('link:click', this.handleLinkClick) */
        this.swup.hooks.off('content:replace', this.beforeContentReplace);
        this.swup.hooks.off('content:replace', this.onContentReplace)
	}
}