import Modal from "./Modal";

const CLOSE_TIMEOUT = 500; //ms
const VIDEO_PLATFORMS = {
    direct: {
        pattern: /\.mp4(\?|$)/,
        format: (url) => `<video controls autoplay><source src="${url}" type="video/mp4"></video>`
    },
    youtube: {
        pattern: /(?:youtube\.com\/.*v=|youtu\.be\/)([0-9A-Za-z_-]{11})/,
        format: (url) => {
            const videoId = url.match(VIDEO_PLATFORMS.youtube.pattern)[1];
            return `<iframe src="https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        }
    },
    vimeo: {
        pattern: /vimeo\.com\/(\d+)/,
        format: (url) => {
            const videoId = url.match(VIDEO_PLATFORMS.vimeo.pattern)[1];
            return `<iframe src="https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
        }
    }
};

export default class extends Modal {
    constructor(m) {
        super(m);

        this.inner = this.$('inner')[0];
        this.videoURL = this.getData('url');
        this.platform = this.detectVideoPlatform(this.videoURL);

        this.closeTimeout = null;
    }

    detectVideoPlatform(url) {
        for (const [platform, { pattern }] of Object.entries(VIDEO_PLATFORMS)) {
            if (pattern.test(url)) {
                return platform;
            }
        }

        return "embed";
    }

    onOpen() {
        const platform = VIDEO_PLATFORMS[this.platform];
        this.inner.innerHTML = platform && platform.format(this.videoURL);
    }

    onClose() {
        this.closeTimeout && clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(() => {
            this.inner.innerHTML = "";
        }, CLOSE_TIMEOUT);
    }
}
